<!-- Widget starts-->
<div class="dashHome">
    <div class="row">
        <div class="col-12 col-md-6 col-lg-3">
            <div class="card">
              <div class="card-body">
                <div class="dashValue">2</div>
                <mat-icon class="dashIcon">work</mat-icon>
                <div class="dashHead">Total Projects</div>
              </div>
            </div>
          </div>
        
          <div class="col-12 col-md-6 col-lg-3">
            <div class="card">
              <div class="card-body">
                <div class="dashValue">1</div>
                <mat-icon class="dashIcon">shopping_cart</mat-icon>
                <div class="dashHead">Purchase Entry</div>
                <div class="dashSubHead">Without GST</div>
              </div>
            </div>
          </div>
        
          <div class="col-12 col-md-6 col-lg-3">
            <div class="card">
              <div class="card-body">
                <div class="dashValue">1</div>
                <mat-icon class="dashIcon">request_quote</mat-icon>
                <div class="dashHead">Purchase Entry</div>
                <div class="dashSubHead">With GST</div>
              </div>
            </div>
          </div>
        
          <div class="col-12 col-md-6 col-lg-3">
            <div class="card">
              <div class="card-body">
                <div class="dashValue">121</div>
                <mat-icon class="dashIcon">inventory2</mat-icon>
                <div class="dashHead">Suppliers</div>
                <div class="dashSubHead">Total Suppliers</div>
              </div>
            </div>
          </div>
        
          <div class="col-12 col-md-6 col-lg-3">
            <div class="card">
              <div class="card-body">
                <div class="dashValue">10</div>
                <mat-icon class="dashIcon">badge</mat-icon>
                <div class="dashHead">Employees</div>
                <div class="dashSubHead">Total Employees</div>
              </div>
            </div>
          </div>
        
          <div class="col-12 col-md-6 col-lg-3">
            <div class="card">
              <div class="card-body">
                <div class="dashValue">20</div>
                <mat-icon class="dashIcon">groups</mat-icon>
                <div class="dashHead">Labours</div>
                <div class="dashSubHead">Total Labours</div>
              </div>
            </div>
          </div>
        
          <div class="col-12 col-md-6 col-lg-3">
            <div class="card">
              <div class="card-body">
                <div class="dashValue">0</div>
                <mat-icon class="dashIcon">payments</mat-icon>
                <div class="dashHead">Debit</div>
                <div class="dashSubHead">Total Debit</div>
              </div>
            </div>
          </div>
        
          <div class="col-12 col-md-6 col-lg-3">
            <div class="card">
              <div class="card-body">
                <div class="dashValue">229</div>
                <mat-icon class="dashIcon">shopping_bag</mat-icon>
                <div class="dashHead">Products</div>
                <div class="dashSubHead">Total Products</div>
              </div>
            </div>
          </div>
    </div>
  </div>
  
  <!-- Widgets ends-->